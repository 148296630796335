.insturctions-list ol {
  list-style: none;
  counter-reset: numbers;
}
.insturctions-list ol li {
  position: relative;
  margin-bottom: 2rem;
}

.insturctions-list ol li:before {
  display: block;
  margin: 20px 150px;
  counter-increment: numbers;
  content: counter(numbers);
  font-size: 25px;
  font-weight: 600;
  border: 3px solid #40a83c;
  border-radius: 50%;
  padding:15px;
  font-family: 'Winner Sans';
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .insturctions-list ol li:before {
        display: block;
        margin: 20px 160px;
        counter-increment: numbers;
        content: counter(numbers);
        font-size: 25px;
        font-weight: 600;
        border: 3px solid #40a83c;
        border-radius: 50%;
        padding: 5px;
      }

}

@media (max-width: 410px) {
    .insturctions-list ol li:before {
        display: block;
        margin: 20px 135px;
        counter-increment: numbers;
        content: counter(numbers);
        font-size: 20px;
        font-weight: 600;
        border: 3px solid #40a83c;
        border-radius: 50%;
        padding: 13px;
      }

}
