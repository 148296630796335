.play-btn{
  position: relative;
}
.play-btn .tooltiptext{
  display: none;
width: 120px;
color: #fff;
padding: 5px 0;
border-radius: 6px;
font-size: 1.2rem;
  
}
.play-btn:hover .tooltiptext{
 display: block;
 position: absolute;
 left:80%;
 border: 1px solid green;
 border-radius: 5px;
 top:-80%;
 background-color: #000;
 overflow: auto;
 padding: 2rem;
 min-width: 20rem;

}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
.play-btn:hover .tooltiptext{
      max-width: 15rem;
      left:40%;


  }
}
.dashboard-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 30%;
    left: 40%;
    right: 40px;
    bottom: 40px;
    background-color: white;
    max-width:25rem;
    max-height: 18rem;
    color: black;
    border-radius:10px;
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 10px;
    text-align: center;
    overflow: auto;

    
  }
  .dashboard-modal p{
      padding:10px;
  }
  .dashboard-modal .modal-actions {
      display: flex;
      width: 100%;
    

      
  }
  
  .dashboard-modal .modal-btn {
      border:0.1px solid  #535353;
      border-collapse: collapse;
      padding:10px;
      flex: 0.5;
      color: blue;
    }

    .dashboard-modal .modal-actions:nth-child(even){
        border-left: none;
    }
  

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
  }
  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .dashboard-modal {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 30%;
      left:20%
     
    }
  }