.info__inner {
    position: relative;
}
.info__inner .tooltiptext{
    display: none;
  width: 120px;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 1.2rem;
    
}
.info__inner:hover .tooltiptext{
   display: block;
   position: absolute;
   left:80%;
   border: 1px solid green;
   border-radius: 5px;
   top:-100%;
   background-color: #000;
   overflow: auto;
   padding: 2rem;
   min-width: 20rem;

}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    .info__inner:hover .tooltiptext{
        min-width: 15rem;


    }
}