.error-message{
    margin-top:15px;
    margin-bottom: 25px;
    color: #ee0200;
    font-weight: 400;
    font-size: 1.7rem;
}
.passwordControl {position: relative;}
.passwordControl .viewPass {position: absolute;right: 10px;top: 35px; border:none;background: transparent;}

/* .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }  */