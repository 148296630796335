.playbook .section__body{
    text-align: left;
    margin-top:5.5rem;
}
.playbook .section__body .form__head{
    margin-bottom: 3rem;
}
.playbook .section-video{
    margin-bottom: 2rem;
    text-align: center;
}
 .videoPlayer{
    
    width: 100% !important;
   height:200px !important;
    width: auto;
}
.playbook .section-points h4 , .playbook .section-points h6{
font-family: "Roboto Condensed";
}
.playbook .section-points ,.playbook .radios{
    margin-bottom: calc(2.26em / 2);
}