.slider__slide{
text-align: left;
}
.carousel .control-dots .dot {
  
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: flex;
  padding: 6px;
  align-items: center;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 9999;
}
.carousel .thumbs-wrapper {
    display: none;
}


.carousel .control-prev.control-arrow {
  max-height: 35px;
  background: #464646;
  opacity: 1;
  left: 90.5%;
  top: 50%;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  z-index: 999;
  border: 1px solid #6d7e6c;
}
.carousel .control-prev.control-arrow:hover,.carousel .control-next.control-arrow:hover{
    background-color:#40A83C;
    transition: 3ms ease-out;
}
.carousel .control-next.control-arrow {
    max-height: 35px;
    top: 20%;
    right: 0%;
    background: #464646;
    opacity: 1;
    z-index: 999999;
    border: 1px solid #6d7e6c;

  }
.carousel .carousel-status {
  right: 5%;
  display: none;
}
.carousel .control-dots .dot{
    width: 12px;
    height: 12px;
    /* background-color: #40A83C; */
    background-color: transparent;
    border: 2px solid #fff;
    opacity: 1;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background-color: #40A83C;
    border:none
}
.carousel .control-dots {
    bottom: -15px;
    max-width: 36.4rem;
    overflow: hidden;
    padding: 0 2rem;
    max-height: 22px;
    overflow-x:auto
}
@media (min-width: 760px){
    .carousel .control-dots {
        bottom: 0px;
        max-height: 22px;

    }
}

